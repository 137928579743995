import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';

import FilterEditDsk from 'shared-vectors/icons/FilterEditDsk';
import { Icon } from 'shared-vectors';

import DialogQB from '@async-components/DialogQB';

import './style.scss';

const setLocs = (parentLocation, locations) => {
  const hasParent = Object.keys(parentLocation).length > 0;
  const hasLocations = locations.length > 0;


  if (!hasParent && !hasLocations) {
    return null;
  }

  if (
    !hasParent
    || (locations.length === 1 && locations[0].hkey === parentLocation.hkey)
  ) {
    const [loc] = locations;
    const level = loc.level === 999
      ? ''
      : ` (${loc.level === 6 ? 'provincia' : 'comune'})`;
    const val = `${loc.name}${level}`;
    return {
      short: val,
      long: val,
    };
  }

  const locationsClone = [...locations];
  const first = locationsClone.shift();
  const firstNameShort = first.name.length > 14
    ? `${first.name.substring(0, 10)}...`
    : first.name;

  return {
    short: `${parentLocation.name}: ${firstNameShort}${locationsClone.length ? ` +${locationsClone.length}` : ''}`,
    long: `${parentLocation.name}: ${first.name}${locationsClone.length ? ` +${locationsClone.length}` : ''}`,
  };
};


const ProposeFiltersDialog = ({
  locations,
  parentLocation,
  showLocs,
  filters,
  activeFilters,
  isMobile,
  setDialog,
  setDialogIsOpen,
  resetHPLastSearch,
  updateFilters,
  setHasBeenReset,
  iosLth12,
}) => {
  const [locs] = useState(setLocs(parentLocation, locations));
  const [hide, setHide] = useState(false);

  const safeShowLocs = showLocs && locs;
  const hasNearby = filters?.nearby;

  const onDialogToggle = (open) => {
    setHide(open);
    setDialogIsOpen(open); 
  };

  const createDialog = () => {
    const dialog = (
      <DialogQB
        locations={locs && locs.long}
        filters={filters}
        activeFilters={activeFilters}
        onClose={onDialogToggle}
        isMobile={isMobile}
        resetHPLastSearch={resetHPLastSearch}
        updateFilters={updateFilters}
        setHasBeenReset={setHasBeenReset}
        handleLocalities={!showLocs}
      />
    );    
    setDialog(dialog);
    onDialogToggle(true);
  };

  const text = `${safeShowLocs ? `a ${locs.short}, ` : ''}${activeFilters.map(fil => fil.displayValue).join(', ')}`;
  const filtersCount = activeFilters.length;

  if (hide) {
    return null;
  }

  return (
    <div className={`pfd${iosLth12 ? ' pfd--stck' : ''}${!safeShowLocs && !hasNearby ? ' pfd--nolocs' : ''}`}>
      <Grid className="pfd__cont b--s c-br--f3 is-clickable">
        <GridItem
          behavior="stretch"
          className="pl--m"
          style={{ width: 'calc(100% - 48px)' }}
        >
          <Grid
            align="center"
            className="pfd__h100"
            onClick={createDialog}
          >
            <p className="pfd__txt tp-s--m tp-w--s c-txt--f0">
              <strong className="tp-w--l">{filtersCount} filtr{filtersCount > 1 ? 'i' : 'o'} attiv{filtersCount > 1 ? 'i' : 'o'}{!showLocs ? ':' : ''}</strong> {text}
            </p>
          </Grid>
        </GridItem>
        <GridItem behavior="fixed">
          <Grid
            align="center"
            className="pfd__h100 c-txt--f0 pr--m pl--m"
            onClick={createDialog}
          >
            <Icon name={FilterEditDsk} width="16px" height="16px" />
          </Grid>
        </GridItem>
      </Grid>
    </div>
  );
};

export default ProposeFiltersDialog;

ProposeFiltersDialog.propTypes = {
  locations: PropTypes.instanceOf(Array),
  parentLocation: PropTypes.instanceOf(Object),
  showLocs: PropTypes.bool,
  filters: PropTypes.instanceOf(Object),
  activeFilters: PropTypes.instanceOf(Array),
  isMobile: PropTypes.bool,
  setDialog: PropTypes.func,
  setDialogIsOpen: PropTypes.func,
  resetHPLastSearch: PropTypes.func,
  updateFilters: PropTypes.func,
  setHasBeenReset: PropTypes.func,
  iosLth12: PropTypes.bool,
};
ProposeFiltersDialog.defaultProps = {
  locations: [],
  parentLocation: {},
  showLocs: true,
  filters: {},
  activeFilters: [],
  isMobile: true,
  setDialog: () => {},
  setDialogIsOpen: () => {},
  resetHPLastSearch: null,
  updateFilters: () => {},
  setHasBeenReset: null,
  iosLth12: false,
};
