import request from 'superagent';
import calculateExpireTimeFromNow from 'shared-utils/src/calculateExpireTimeFromNow';
import manageSigninData from 'shared-utils/src/manageSigninData';
import parseJwt from 'shared-utils/src/parseJWT';
import checkIfModalConsentHasToShow from 'shared-utils/src/checkIfModalConsentHasToShow';
import checkConsentMandatoryLS from 'shared-utils/src/checkConsentMandatoryLS';
import { deleteItem } from 'shared-utils/src/localStorage';

// import jsonwebtoken from 'jsonwebtoken';

import { getUserFormData } from './index';

const userProxyBase = '/portal-srp/api/v1/user/';

const session = 'sessionStorage';
const local = 'localStorage';
const cookie = 'cookie';

const storageType = {
  session_portal: cookie,
  remember_me: cookie,
  publisherCookie: cookie,
  session_portal_local: cookie,
  remember_me_local: cookie,
  publisher_cookie_local: cookie,
  session_portal_dev: cookie,
  remember_me_dev: cookie,
  publisher_cookie_dev: cookie,
  session_portal_stg: cookie,
  remember_me_stg: cookie,
  publisher_cookie_stg: cookie,
  favoritesLoaded_stg: session,
  'session.sig': session,
  session,
  favorites: local,
  searches: local,
  refreshMKTGConsent: local,
  userSettings: session,
  tempFormData: session,
  searchRefreshBookmarks: local,
  lastMyCasaSynch: local,
  accountSigninName: local,
  consentMandatory: local,
};

export const logMycasaCall = (req) => {
  const mycasaLogProxy = '/portal-srp/api/v1/mycasa/log/call';
  request
    .post(mycasaLogProxy)
    .set('Accept', 'application/json')
    .send(req)
    .withCredentials()
    .end(() => { });
};

const getCookieDomain = () => {
  const hostParts = location.hostname.split('.');
  if (hostParts.indexOf('localhost') > -1) { // local
    return '';
  } if (hostParts.indexOf('dev') > -1) { // dev environment
    return `;domain=.${hostParts.slice(hostParts.indexOf('dev')).join('.')}`;
  } if (hostParts.length > 2) { // prod environment
    return `;domain=.${hostParts.slice(hostParts.length - 2).join('.')}`;
  }
  return '';
};

/**
 * Create cookie on client side
 * @param {string} cname  cookie name
 * @param {string} cvalue cookie value
 * @param {int}    exdays expiration days
 */
export function setCookie(cname, cvalue, exdays, { expiresDateRememberMe } = {}) {
  let d = new Date();
  let expires = exdays === 0 ? 'expires=0' : `expires=${d.toUTCString()}`;
  if (expiresDateRememberMe) {
    d = new Date(expiresDateRememberMe);
    expires = `expires=${d.toUTCString()}`;
  } else {
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    expires = exdays === 0 ? 'expires=0' : `expires=${d.toUTCString()}`;
  }
  const domain = getCookieDomain();
  document.cookie = `${cname}=${cvalue}; ${expires}; path=/ ${domain}`;
}

/**
* get cookie based on name
* @param  {string} cname cookie name
* @return {string}       cookie content
*/

function getCookie(cname) {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function deleteCookie(cname) {
  const d = new Date();
  d.setTime(d.getTime() + (-365 * 10 * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  const domain = getCookieDomain();
  document.cookie = `${cname}=; ${expires}; path=/ ${domain}`;
}

export function setItem(key, value, { expiresDateRememberMe } = {}) {
  if (!window) {
    return;
  }
  switch (storageType[key]) {
    case session:
      window.sessionStorage.setItem(key, value);
      break;
    case cookie:
      setCookie(key, value, 30, { expiresDateRememberMe });
      break;
    default:
      window.localStorage.setItem(key, value);
  }
}

const destroySessionCookieOnServer = (account) => {
  if (account) {
    window.location.href = `${account}/logout`;
  }
};

export function removeItem(key, account) {
  if (!window) {
    return;
  }
  switch (storageType[key]) {
    case session:
      window.sessionStorage.removeItem(key);
      deleteCookie(key);
      break;
    case cookie:
      destroySessionCookieOnServer(account);
      deleteCookie(key);
      break;
    default:
      window.localStorage.removeItem(key);
  }
}

export function getItem(key) {
  if (!window) {
    return;
  }

  switch (storageType[key]) {
    case session:
      return window.sessionStorage.getItem(key);
    case cookie:
      return getCookie(key);
    default:
      return window.localStorage.getItem(key);
  }

}

/**
 * Fill formData based on logged User
 * @param {Object} fd Old formData
 * @param {Object} ud User
 * @return {Object} New formData
 */
export const fillFormData = (ud) => {
  const nfd = { ...getUserFormData() };
  let consent = false;
  // const commercialConsent = ud.selfConsents ? ud.selfConsents.filter(cnsnt => cnsnt.consentId === 1)[0] : null;
  // if (commercialConsent && commercialConsent.consentFlag) {
  //   consent = true;
  // }
  if (
    ud.consents
    && Object.keys(ud.consents).length > 0
    && Object.keys(ud.consents).includes('1')
  ) {
    consent = ud.consents['1'];
  }
  nfd.email = ud.email || ud.username;
  nfd.firstName = ud.displayname;
  nfd.lastName = '';
  nfd.name = ud.displayname;
  nfd.telephone = ud.phone;
  nfd.consent = consent;
  return nfd;
};

const setLoginApiCallback = ({
  jwt,
  loginApiCallBack,
  handleSocialLoginClb,
  accountSignin,
  loginType,
  loginDate,
  from,
  hasToShowModalConsent,
}) => {
  const decodedNewJwt = parseJwt(jwt);
  if (!decodedNewJwt) {
    loginApiCallBack({
      isAuthenticated: false,
      checked: true,
      hasToShowModalConsent,
      handleSocialLoginClb,
    }, null, { from });
  }

  const composedFD = fillFormData(decodedNewJwt.user);

  // se l'utente si è loggato da account
  // scrivo i nuovi dati sul record LS senza scatenare l'evento analytics
  if (accountSignin) {
    manageSigninData({
      login: true,
      track: false,
      medium: loginType,
      email: decodedNewJwt.user.email,
      formData: composedFD,
      timeStamp: loginDate,
    });
  }

  loginApiCallBack({
    isAuthenticated: true,
    checked: true,
    hasToShowModalConsent,
    handleSocialLoginClb,
    id: decodedNewJwt.user.id,
    displayname: decodedNewJwt.user.displayname,
    username: decodedNewJwt.user.email,
    phone: decodedNewJwt.user.phone,
    isPublisherBlacklisted: decodedNewJwt.user.isPublisherBlacklisted,
    sess: jwt,
    formData: composedFD,
  }, {
    loginDate,
    loginMedium: loginType,
  }, { from });
};

const getConsentType = async ({ consentUri }) => {
  const bodyReq = {
    accountUrl: consentUri,
    payload: {},
  };

  const gcPromise = await request
    .post(consentUri)
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .send(JSON.stringify(bodyReq))
    .withCredentials();
  if (gcPromise.error) {
    console.log('Error consent uri >>:', gcPromise.error);
    return gcPromise.error;
  }
  return gcPromise?.body || [];
};

export const getSessionUser = async (
  loginApiCallBack,
  loginUri,
  {
    handleLoginRes = null,
    from = null,
    cookiesConfig: {
      cookieSessionPortal,
      cookieRememberMeKey,
      cookiePublisherKey,
      accountSigninName,
    } = {},
  } = {}) => {
  const handleSocialLoginClb = handleLoginRes;
  const remmeName = cookieRememberMeKey;
  const publName = cookiePublisherKey;
  const refreshConsentName = 'refreshMKTGConsent';
  const remme = getItem(remmeName);
  const remmeCookie = getCookie(remmeName);
  const publCookie = getCookie(publName);
  const refreshConsent = getItem(refreshConsentName);
  const shouldRefreshForConsentUpdate = getItem('csa__force__consent_refresh');
  if (shouldRefreshForConsentUpdate) {
    deleteItem('csa__force__consent_refresh');
  }

  const consentUri = `${loginUri}/client-account/api/v1/user/findconsents`;
  loginUri += '/client-account/api/v1/user/getjwt';
  if (!remme && !remmeCookie) {
    loginApiCallBack({
      isAuthenticated: false,
      checked: true,
      handleSocialLoginClb,
    }, null, { from });
    return;
  }
  if (remmeCookie === 'undefined') {
    deleteCookie(remmeName);
    loginApiCallBack({
      isAuthenticated: false,
      checked: true,
      handleSocialLoginClb,
    }, null, { from });
    return;
  }
  // USER COME BACK
  const decodedRemme = parseJwt(remmeCookie);

  if (
    !decodedRemme
    || decodedRemme.exp < Date.now() / 1000
  ) { // rememeber me expired ????
    loginApiCallBack({
      isAuthenticated: false,
      checked: true,
      handleSocialLoginClb,
    }, null, { from });
    return;
  }

  const iat = decodedRemme.iat * 1000;
  const now = new Date();
  const remmeIat = (new Date(iat)).getTime();
  const nowTimeStamp = now.getTime();
  const microSecondsDiff = Math.abs(nowTimeStamp - remmeIat);
  const daysDiff = Math.round(microSecondsDiff / (1000 * 60 * 60 * 24));
  const sessionName = cookieSessionPortal;
  const sessRemme = getItem(sessionName);
  const sessDecoded = parseJwt(sessRemme);

  // se l'utente è loggato, controllo se si è loggato da account
  // leggendo l'apposito cookie
  const accountSignin = getItem(accountSigninName);
  if (accountSignin) {
    // se il cookie esiste, lo cancello
    deleteItem(accountSigninName);
  }

  /**
   * TECH-4949: Call /getjwt once a day
   */
  /**
   * TECH-6296: Call /getjwt if user changed the consent in account
   */
  if (daysDiff > 1 || !sessRemme || refreshConsent || shouldRefreshForConsentUpdate) {
    try {
      const bodyReq = {
        accountUrl: loginUri,
        payload: {
          socialjwt: remmeCookie,
          ...publCookie && { publisherCookie: publCookie },
          from: 'portal18',
          options: {
            selfContents: true,
          },
        },
      };

      request
        .post(`${userProxyBase}getJWT`)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .send(JSON.stringify(bodyReq))
        .withCredentials()
        .end(async (err, res) => {
          if (err) {
            console.log(`getSessionUser error: ${err}`);
            loginApiCallBack({
              isAuthenticated: false,
              checked: true,
              hasToShowModalConsent: false,
              handleSocialLoginClb,
            }, null, { from });
          } else {

            const {
              rememberMe,
              jwt,
              publisherCookie,
              error,
              errorData,
              expiresDateRememberMe,
            } = res.body.response;

            if (error) {
              console.log(`getSessionUser error: ${errorData}`);
              loginApiCallBack({
                isAuthenticated: false,
                checked: true,
                hasToShowModalConsent: false,
                handleSocialLoginClb,
              }, null, { from });
            } else {


              // setItem('session.sig', id);
              setItem(cookieSessionPortal, jwt, { expiresDateRememberMe: calculateExpireTimeFromNow({ tms: 3600 }) });
              setItem(cookieRememberMeKey, rememberMe, { expiresDateRememberMe });
              publisherCookie && setItem(cookiePublisherKey, publisherCookie, { expiresDateRememberMe });
              const decodedRememberMe = parseJwt(rememberMe);
              const { loginType } = decodedRememberMe;
              const { iat: loginDate } = decodedRemme;

              // prendo i consensi dell'utente e controllo se sono stati settati tutti
              // quelli necessari, altrimenti si mostra la modale lato client
              const hasToCallConsent = checkConsentMandatoryLS({ sessRemme });
              let hasToShowModalConsent = false;
              if (hasToCallConsent) {
                const consents = await getConsentType({ consentUri }) || [];
                hasToShowModalConsent = checkIfModalConsentHasToShow({ consents });
                if (!hasToShowModalConsent && sessDecoded?.user?.id) {
                  setItem('consentMandatory', { id: sessDecoded?.user?.id });
                }
              }

              setLoginApiCallback({
                jwt,
                loginApiCallBack,
                handleSocialLoginClb,
                accountSignin,
                loginType,
                loginDate,
                from,
                hasToShowModalConsent,
              });
            }
          }
        });

    } catch (error) {
      console.log(`getSessionUser error: ${error}`);
      loginApiCallBack({
        isAuthenticated: false,
        checked: true,
        hasToShowModalConsent: false,
        handleSocialLoginClb,
      }, null, { from });
    }
    if (refreshConsent) {
      deleteItem(refreshConsentName);
    }
  }
  if (sessRemme) {
    // prendo i consensi dell'utente e controllo se sono stati settati tutti
    // quelli necessari, altrimenti si mostra la modale lato client
    const hasToCallConsent = checkConsentMandatoryLS({ sessRemme });
    let hasToShowModalConsent = false;
    if (hasToCallConsent) {
      const consents = await getConsentType({ consentUri }) || [];
      hasToShowModalConsent = checkIfModalConsentHasToShow({ consents });
      if (!hasToShowModalConsent && sessDecoded?.user?.id) {
        setItem('consentMandatory', JSON.stringify({ id: sessDecoded.user.id }));
      }
    }
    const { loginType, iat: loginDate } = decodedRemme;
    setLoginApiCallback({
      jwt: sessRemme,
      loginApiCallBack,
      handleSocialLoginClb,
      accountSignin,
      loginType,
      loginDate,
      from,
      hasToShowModalConsent,
    });
  }
};

/**
 * LogIn of the user
 * @param {Function} modalCallback callback for the login that has to be handle
 * with a modal
 * @param {Function} handleLoginRes callback after the login of the user
 * @param {Object} opt contains info for the login of the user
 */
export const logIn = (handleLoginRes, opt, accountUrl, refreshUser, {
  cookiesConfig: {
    cookieSessionPortal,
    cookieRememberMeKey,
    cookiePublisherKey,
  } = {},
  from = null,
}) => {
  const {
    email, password, app, path,
    postLoginAction,
  } = opt;

  const defaultErrorMessage = 'Ci scusiamo per il disagio, al momento non è possibile eseguire l\'operazione richiesta';

  if (email === '' || password === '') {
    return;
  }
  try {
    const bodyReq = {
      email,
      password,
      app,
      path,
    };

    const payload = {
      accountUrl,
      bodyReq,
      defaultErrorMessage,
    };

    request
      .post(`${userProxyBase}login`)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .send(JSON.stringify(payload))
      .withCredentials()
      .end((err, res) => {
        if (err) {
          handleLoginRes({ error: { type: 'generic', message: defaultErrorMessage }, postLoginAction });
        } else {
          const {
            rememberMe,
            publisherCookie,
            jwt,
            redirect,
            error,
            msg,
            expiresDateRememberMe,
          } = res.body.response;

          if (error) {
            handleLoginRes({ error: { type: 'generic', message: msg }, postLoginAction });
          } else {
            // se non abbiamo il rememberMe non è forse meglio cacciar fuori handleLoginRes({ error: { type: 'generic', message: msg }, postLoginAction });
            if (rememberMe) {
              setItem(cookieRememberMeKey, rememberMe, { expiresDateRememberMe });
            }
            if (publisherCookie) {
              setItem(cookiePublisherKey, publisherCookie, { expiresDateRememberMe });
            }
            if (redirect) {
              window.location.href = redirect;
            } else {
              setItem(cookieSessionPortal, jwt, { expiresDateRememberMe: calculateExpireTimeFromNow({ tms: 3600 }) });
              handleLoginRes({ success: true, postLoginAction, jwt });
              refreshUser({ user: jwt, rememberMe }, null, { from });
            }
          }
        }
      });
  } catch (error) {
    handleLoginRes({ error: { type: 'generic', message: defaultErrorMessage }, postLoginAction });
  }
};

/**
 * User registration
 * @param {Function} modalCallback callback for the reg that has to be handle
 * with a modal
 * @param {Function} handleRegistrationRes callback after the reg of the user
 * @param {Object} opt contains info for the reg of the user
 */
export const registerIn = (handleRegistrationRes, opt, accountUrl) => {
  const {
    displayname,
    email,
    password,
    app,
    path,
    postRegistrationAction,
    gtoken,
    flagconsent,
    typeconsent,
  } = opt;

  const payload = {
    accountUrl,
    bodyReq: {
      displayname,
      email,
      password,
      app,
      path,
      flagconsent,
      typeconsent,
      token: gtoken,
    },
  };

  request
    .post(`${userProxyBase}registration`)
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .send(JSON.stringify(payload))
    .withCredentials()
    .end((err, res) => {
      if (err) {
        handleRegistrationRes({ error: { type: 'generic', message: 'Errore generico' }, postRegistrationAction });
      } else {
        const {
          error,
          msg,
        } = res.body.response;
        if (error) {
          handleRegistrationRes({ error: { type: 'generic', message: msg }, postRegistrationAction });
        } else {
          handleRegistrationRes({ success: true, postRegistrationAction });
        }
      }
    });

};

/**
 * User registration silently
 * @param {Function} modalCallback callback for the reg that has to be handle
 * with a modal
 * @param {Function} handleRegistrationRes callback after the reg of the user
 * @param {Object} opt contains info for the reg of the user
 */
export const registerInSilently = (handleRegistrationRes, opt, accountUrl) => {
  const {
    email,
    password,
    app,
    path,
    jwt,
    jti,
    postRegistrationAction,
    displayname,
    gtoken,
    flagconsent,
    typeconsent,
  } = opt;

  const payload = {
    accountUrl,
    bodyReq: {
      email,
      password,
      app,
      path,
      jwt,
      jti,
      displayname,
      flagconsent,
      typeconsent,
      token: gtoken,
    },
  };

  request
    .post(`${userProxyBase}registrationsilently`)
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .send(JSON.stringify(payload))
    .withCredentials()
    .end((err, res) => {
      if (err) {
        handleRegistrationRes({ error: { type: 'generic', message: 'Errore generico' }, postRegistrationAction });
      } else {
        const {
          error,
          msg,
        } = res.body.response;
        if (error) {
          handleRegistrationRes({ error: { type: 'generic', message: msg }, postRegistrationAction });
        } else {
          handleRegistrationRes({
            success: true,
            postRegistrationAction,
            email,
            password,
          });
        }
      }
    });
};

export const googleOneTapLogin = async (data, refreshUser, {
  cookiesConfig: {
    cookieSessionPortal,
    cookieRememberMeKey,
    cookiePublisherKey,
  } = {},
  accountDomain = 'https://www.casa.it',
  from = null,
}) => {
  const {
    jwt,
    rememberMe,
    publisherJwt,
    expiresDateRememberMe,
    newUser,
  } = data;
  setItem(cookieRememberMeKey, rememberMe, { expiresDateRememberMe });
  setItem(cookieSessionPortal, jwt, { expiresDateRememberMe: calculateExpireTimeFromNow({ tms: 3600 }) });
  if (publisherJwt) {
    setItem(cookiePublisherKey, publisherJwt, { expiresDateRememberMe });
  }
  const consentUri = `${accountDomain}/client-account/api/v1/user/findconsents`;
  const hasToCallConsent = checkConsentMandatoryLS({ sessRemme: jwt, from: 'googleonetap' });
  let hasToShowModalConsent = false;
  if (hasToCallConsent) {
    const consents = await getConsentType({ consentUri }) || [];
    hasToShowModalConsent = checkIfModalConsentHasToShow({ consents });
  }
  refreshUser({
    user: jwt,
    rememberMe,
    newUser,
  }, true, { from, hasToShowModalConsent });
};

export const logOut = async (
  account = 'https://www.casa.it/my',
) => {
  const logoutRes = await request
    .get(`${account}/logout?redirect=false`)
    .set('Accept', 'application/json')
    .withCredentials();

  return logoutRes;
};
