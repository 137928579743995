const selectedIsParent = (locations, parentLocation) => {
  const hasParent = !!Object.keys(parentLocation).length > 0;  
  if (hasParent) {
    return (locations && locations[0] && (locations[0].hkey === parentLocation.hkey));
  }
  return (locations && locations[0] && (locations[0].level === 9 || locations[0].level === 6 || locations[0].level === 118));
};

export default (locations, parentLocation, levelFilter = null) => {
  if (selectedIsParent(locations, parentLocation)) {
    return [];
  }
  if (levelFilter && (locations || []).length) {
    return locations[0].level === levelFilter ? locations : [];
  }
  return locations || [];
};
