import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'shared-components/components/Grid';

import { getActiveFilters } from '@filters/helpers';

import { getTitle } from './helpers';

const BaloonMessageNoResults = ({ isAgencySrp, filters, className }) => {
  const { activeFiltersCount } = getActiveFilters(filters);
  return (
    <Grid className={`nrHeader b--s p--m ${className}`.trim()} direction="column" align="center" justify="center">
      <p className="nrHeader_title tp-s--l tp-w--l mb--xs c-txt--f0">
        {getTitle({ isAgencySrp })}
      </p>
      {activeFiltersCount > 0 && (
        <p className="nrHeader_sub-title tp-s--m tp-w--s c-txt--f0">
          Prova a modificare o eliminare qualche filtro
        </p>
      )}
    </Grid>
  );
};

BaloonMessageNoResults.propTypes = {
  isAgencySrp: PropTypes.bool,
  filters: PropTypes.instanceOf(Object),
  className: PropTypes.string,
};

BaloonMessageNoResults.defaultProps = {
  isAgencySrp: false,
  filters: {},
  className: '',
};

export default BaloonMessageNoResults;
