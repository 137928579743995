// import React from 'react';
import request from 'superagent';

import provinceShortToLongList from 'shared-constants/src/locations/provinceShortToLong';
import RESUME_LS_RECORD from 'shared-constants/src/resumeLSRecord';
import { setItem } from 'shared-utils/src/localStorage';
import MAP_LOCALITY from 'shared-constants/src/mapGenericLocality';

import { provinceShortToLongListHub } from '../../../../constants/Localities';

export const defaultToken = [
  {
    level: 999,
    hkey: 0,
    name: 'Tutte le località',
  },
];

export const defaultTokenName = ['Tutte le località'];

export const getProvinceShortName = (long) =>
  Object.keys(provinceShortToLongList).find(
    (key) => provinceShortToLongList[key] === long
  );
export const getProvinceShortNameHub = (long) =>
  Object.keys(provinceShortToLongListHub).find(
    (key) => provinceShortToLongListHub[key] === long
  );

export const formatTokens = (el) => {
  let text = el.name;
  if (el.type === 'postalcode') {
    text = el.postalcode.toString();
  } else if (el.levels) {
    switch (el.level) {
      case 6:
        text += ' (provincia)';
        break;
      case 9:
        text += ` (${getProvinceShortName(el.levels.L6)})`;
        break;
      case 10: // area
        text += ` - ${el.levels.L9} (${getProvinceShortName(el.levels.L6)})`;
        break;
      default:
        text = el.name;
    }
  }
  return text;
};

export const computeCorrectParentLocation = (
  newLocation,
  parentLocation,
  tempParentLocation,
) => {
  if (
    parentLocation.length > 0 &&
    newLocation[0].levels[`L${parentLocation[0].level}`] ===
      parentLocation[0].name
  ) {
    return parentLocation;
  }
  if (
    tempParentLocation.length > 0 &&
    newLocation[0].levels[`L${tempParentLocation[0].level}`] ===
      tempParentLocation[0].name
  ) {
    return tempParentLocation;
  }
  if (newLocation[0].level === 10 || newLocation[0].level === 11) {
    return [
      {
        id: newLocation[0].parent.town.id,
        name: newLocation[0].levels.L9,
        shouldCallSmartSuggest: true,
      },
    ];
  }
  return [];
};

const getLocationSlugByType = (type, slugs) => {
  switch (type) {
    case 'province':
      return slugs.L6;
    case 'town':
      return slugs.L9;
    case 'zone':
      return slugs.L10;
    case 'block':
      return slugs.L11;
    default:
      return slugs.L4;
  }
};

export const getLocationChildrenUri = (location) => {
  if (location.children_uri) {
    return location.children_uri;
  }
  if (location.level && location.slug) {
    let locType = 'province';
    if (location.level === 9) {
      locType = 'town';
    } else if (location.level === 118) {
      locType = 'metroline';
    }
    return `/lh/v2/${locType}/${location.slug}?site=it_casa`.toLowerCase();
  }
  if (location.type && location.slugs) {
    const slug =
      typeof location.slugs === 'string'
        ? location.slugs
        : getLocationSlugByType(location.type, location.slugs);
    return `/lh/v2/${location.type}/${slug}?site=it_casa`.toLowerCase();
  }
};

const extractBlocks = (suggestions) => {
  const suggestionsWithBlocks = [...suggestions];

  for (let index = 0; index < suggestionsWithBlocks.length; index += 1) {
    const loc = suggestionsWithBlocks[index];

    if (loc.level === 11) {
      if (!('isNotChild' in loc)) {
        loc.isNotChild = true;
      }
    } else if (
      loc.level === 10 &&
      loc.has_children &&
      loc.children &&
      loc.children.length > 0
    ) {
      loc.children.forEach((child, x) => {
        const block = {
          ...child,
          isLastChild: x === loc.children.length - 1,
          isNotChild: false,
        };
        const updatedIndex = index + 1 + x;
        suggestionsWithBlocks.splice(updatedIndex, 0, block);
      });
    }
  }
  return suggestionsWithBlocks;
};

const allowZonesJustForSome = (suggestions, allowedCities) =>
  [...suggestions].filter(
    (loc) =>
      loc.level !== 10 || allowedCities.indexOf(loc.parent.town.slug) > -1
  );

export const manageBuildingCondition = (category) => {
  const building_condition = category === 'nuove-costruzioni' ? 'nuovo' : null;
  const categoryForLH = building_condition ? 'case' : category;
  return { categoryForLH, building_condition };
};

export const computeMixedLocations = (locations, doesParentLocationExist) => {
  let mixedLocalities = false;
  let mixedLocalitiesToShow = [];
  const upperLevelMap = {
    10: 'town',
    9: 'province',
    6: 'region',
  };
  // ci sono >1 località ma non il parentLocation
  if (locations.length > 1 && !doesParentLocationExist) {
    // quindi le località hanno diversi parents
    mixedLocalities = true;
    // prendo i livelli di tutte le località presenti
    const levels = locations.map((loc) => loc.level);
    // elimino i duplicati
    const noDuplicatesLevels = [...new Set(levels)];

    // se tutte le località hanno lo stesso livello
    if (noDuplicatesLevels.length === 1) {
      // se sono delle zone devo prendere i nomi delle città di appartenenza
      if (noDuplicatesLevels[0] === 10) {
        const parents = locations.map(
          (loc) => loc.parent[upperLevelMap[noDuplicatesLevels[0]]].name
        );
        mixedLocalitiesToShow = [...new Set(parents)];
        // se sono comuni o regioni devo prendere i nomi
      } else if (noDuplicatesLevels[0] === 9 || noDuplicatesLevels[0] === 5) {
        mixedLocalitiesToShow = locations.map((loc) => loc.name);
        // se sono delle province devo prendere i nomi + 'provincia'
      } else if (noDuplicatesLevels[0] === 6) {
        mixedLocalitiesToShow = locations.map((loc) => `${loc.name} provincia`);
      }
      // localities have mixed levels
    } else {
      const localitiesByLevel = {};
      noDuplicatesLevels.forEach((level) => {
        localitiesByLevel[level] = locations.filter(
          (loc) => loc.level === level
        );
      });
      if (localitiesByLevel[10] && localitiesByLevel[10].length > 0) {
        const zoneParents = localitiesByLevel[10].map(
          (loc) => loc.parent[upperLevelMap[10]].name
        );
        mixedLocalitiesToShow.push([...new Set(zoneParents)].join(', '));
      }
      if (localitiesByLevel[9] && localitiesByLevel[9].length > 0) {
        const townNames = localitiesByLevel[9].map((loc) => loc.name);
        mixedLocalitiesToShow.push([...new Set(townNames)].join(', '));
      }
      if (localitiesByLevel[6] && localitiesByLevel[6].length > 0) {
        const provinceNames = localitiesByLevel[6].map(
          (loc) => `${loc.name} provincia`
        );
        mixedLocalitiesToShow.push([...new Set(provinceNames)].join(', '));
      }
    }
  }
  return {
    mixedLocalities,
    mixedLocalitiesToShow,
  };
};

const proxyBaseUrl = '/portal-srp/api/v1/smartsuggest/';
const proxyBaseApiReq = '/portal-srp/api/v1/apireq/';

const suggChannel = (channel) => {
  if (!channel) {
    return '';
  }
  return `&channel=${channel !== 'vendita' ? 'affitto' : 'vendita'}`;
};

const suggPTypeGroup = (channel, propertyTypeGroup) => {
  if (channel && channel === 'affitto-breve') {
    return '&property_type_group=case-vacanza';
  }
  if (!propertyTypeGroup) {
    return '';
  }
  return `&property_type_group=${
    propertyTypeGroup === 'nuove-costruzioni'
      ? 'case&building_condition=nuovo'
      : propertyTypeGroup
  }`;
};

export const getSuggestionsRaw = async (qs, publisherId, channel, propertyTypeGroup, isAgency, localitiesWithAgencyZones, isLanding) => {
  const payload = {
    qs,
    channel: suggChannel(channel),
    pTypeGroup: suggPTypeGroup(channel, propertyTypeGroup),
    publisher: publisherId ? `&pub_id=${publisherId}` : '',
    blocksAndNoAdmin: isAgency || isLanding ? '' : ',11,118,120&l11_hierarchic=true&with_nonadmin_levels=true',
    isLanding,
  };
  const {
    body: { data },
  } = await request
    .post(`${proxyBaseUrl}suggest`)
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .send(JSON.stringify(payload))
    .withCredentials();
  return isAgency
    ? allowZonesJustForSome(data, localitiesWithAgencyZones)
    : extractBlocks(data);
};

/**
 * @param {string} qs
 * @returns array of google suggestions
 */
export const getGoogleFromLHSuggestionsRaw = async (qs, myuuid, isFirstCall) => {
  const payload = {
    qs,
    myuuid,
    isFirstCall,
  };
  const {
    body: { data },
  } = await request
    .post(`${proxyBaseUrl}suggestWithGoogle`)
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .send(JSON.stringify(payload))
    .withCredentials();
  return data;
};
/**
 *
 * @param {number} place_id
 * @returns array of one item that describe google place
 */
export const getPlaceWithGoogleFrom = async ({ place_id, uuid }) => {
  const payload = {
    place_id,
    uuid,
  };
  const {
    body: { data },
  } = await request
    .post(`${proxyBaseUrl}reverse`)
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .send(JSON.stringify(payload))
    .withCredentials();
  return data;
};

export const fetchLocalityChildren = async (
  childrenUri,
  {
    channel,
    category,
    buildingCondition,
    publisherId,
    withCount = true,
    withParent = true,
    withZones = true,
    isZone,
    isMetro,
    isAgency,
    isCercaCasa = false,
  } = {}
) => {
  const payload = {
    childrenUri,
    conf: {
      channel,
      category,
      buildingCondition,
      publisherId,
      withCount,
      withParent,
      withZones,
      isZone,
      isMetro,
      isAgency,
      isCercaCasa,
    },
  };

  const sugg = await request
    .post(`${proxyBaseUrl}localityChildren`)
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .send(JSON.stringify(payload))
    .withCredentials();
  const { data, error } = sugg.body;
  if (error) {
    return { error };
  }
  return {
    parentCount: data.count,
    parentTXTCount: data.text_count,
    locations: data.locations,
  };
};

export const getPublisherPopularLocalities = async (payload) => {
  const sugg = await request
    .post(`${proxyBaseUrl}publisherLocalities`)
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .send(JSON.stringify(payload))
    .withCredentials();
  const { data } = sugg.body;
  return data;
};

/**
 *
 * NO FALLBACK FOR THIS LH CALL
 *
 */

export const fetchLocationById = async (id) => {
  const sugg = await request
    .post(`${proxyBaseUrl}locationById`)
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .send(JSON.stringify({ locId: id }))
    .withCredentials();
  const {
    data: { location },
  } = sugg.body;
  return location;
};
/**
 * try to get the uri from radius coordinates
 * taken from googlefallback path
 * @param {object} radiusCoordinates
 */
export const getUriFromRadiusCoordinates = async ({
  item,
  filters,
  apireqParams,
}) => {
  const tmpFilt = JSON.parse(JSON.stringify(filters));
  tmpFilt.geocircle = {
    circle: [
      {
        distance: 500, // TEMP
        center: item.geo.center_point,
        
      },
    ],
    bbox: item.geo.bbox,
    zoom: item.geo.zoom || 16,
  };
  delete tmpFilt.geopolygon;
  delete tmpFilt.nearby;
  setItem(RESUME_LS_RECORD, { filters: tmpFilt, locations: [MAP_LOCALITY], parentLocation: {} });
  const payload = {
    apireqParams,
    filters: tmpFilt,
    locations: [],
    pageType: 'searchMap',
  };

  const proxy = await request
    .post(proxyBaseApiReq)
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .withCredentials()
    .send(payload);
  const {
    body: { apireq },
  } = proxy;
  window.location.href = apireq.uri;
};

/**
 * Try to go to srp map with coords from lh 
 * take from googlefallback path
 * @param {obj} item 
 * @param {obj} filters 
 * @param {obj} apireqParams 
 */
export const goToExploreMap = async ({
  item,
  filters,
  apireqParams,
}) => {
  const tmpFilt = JSON.parse(JSON.stringify(filters));
  tmpFilt.geopolygon = {
    polygon: item.geo.polygon,
    zoom: item.geo.zoom || 16,
  };
  delete tmpFilt.geocircle;
  delete tmpFilt.nearby;
  const payload = {
    apireqParams,
    filters: tmpFilt,
    locations: [],
    pageType: 'searchMap',
  };
  setItem(RESUME_LS_RECORD, { filters: tmpFilt, locations: [MAP_LOCALITY], parentLocation: {} });

  const proxy = await request
    .post(proxyBaseApiReq)
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .withCredentials()
    .send(payload);
  const {
    body: { apireq },
  } = proxy;
  window.location.href = apireq.uri;
};
