// @flow
import React from 'react';

import { setWindowSize } from 'shared-utils/src/lockScreen/utils';
import manageSigninData from 'shared-utils/src/manageSigninData';
import parseJwt from 'shared-utils/src/parseJWT';
import getShowConsentRequest from 'shared-utils/src/getShowConsentRequest';
import { getItem } from 'shared-utils/src/localStorage';

import BOOKMARKED_SEARCHES_LS from 'shared-constants/src/bookmarksLSRecord';
import BOOKMARKED_ADS_LS from 'shared-constants/src/savedAdsLSRecord';
import SYNCH_WITH_MYCASA from 'shared-constants/src/synchWithMycasa';

import isEqual from '@lodash/isEqual';
import reducerApp from '@state/reducers/app';
import agencyHomeSEOData from '@components/agencyHomePage/helpers/seoData';
import { fetch } from '../../common/components';

import { userDevice } from '../helpers/DOMHelpers';

import {
  getSessionUser,
  logIn,
  logOut,
  fillFormData,
  googleOneTapLogin,
  registerIn,
  registerInSilently,
  removeItem,
} from '../helpers/UserHelpers';

import homeSEOData from '../home/helpers/seoData';
import { hubSEOData } from '../hubPage/helpers/seoData';

export default function UiHandlerHOC(Content, fetchFunction) {
  class UiHandlerComponent extends React.Component {
    static displayName = 'UiHandlerHOC';

    constructor(props) {
      super(props);

      this.state = {
        device: '',
        viewport: { width: '', height: '' },
        touchscreen: false,
        isLocalStorageAvailable: false,
        isShortDesktop: false,
        iosLth12: false,
        modal: false,
        bookmarkConfirm: false,
        user: {
          displayName: '',
          id: 0,
          isAuthenticated: false,
          checked: false,
          sess: '',
          username: '',
        },
        waitingCMP: true,
        showConsentModal: false,
      };

      this.seo_data = {};
      const {
        home,
        agencyHome,
        searchMap,
        hubPage,
        agentPro,
        account,
        subscription,
        qrcodeHandler,
        cercacasa,
        deactivation,
        deactivationAlerts,
        manageConsents,
      } = this.props;
      const isAgencyHome = Object.keys(agencyHome).length > 0;
      const isHome = Object.keys(home).length > 0 && !isAgencyHome;
      const isSrpMap = Object.keys(searchMap).length > 0;
      const isHubPage = Object.keys(hubPage).length > 0 && !hubPage.error;
      const isAgentPro = Object.keys(agentPro).length > 0 && !agentPro.error;
      const isAccount = Object.keys(account).length > 0 && !account.error;
      const isSubscription = Object.keys(subscription).length > 0 && !subscription.error;
      const isQrcodeHandler = Object.keys(qrcodeHandler).length > 0 && !qrcodeHandler.error;
      const isLanding = Object.keys(cercacasa).length > 0 && !cercacasa.error;
      const isDeactivation = Object.keys(deactivation).length > 0 && !deactivation.error;
      const isDeactivationAlerts = Object.keys(deactivationAlerts).length > 0 && !deactivationAlerts.error;
      const isManageConsents = Object.keys(manageConsents).length > 0 && !manageConsents.error;
      this.isHome = isHome;

      this.getSeoData({
        isHome,
        isHubPage,
        isSrpMap,
        isAgencyHome,
        isAgentPro,
        isAccount,
        isSubscription,
        isQrcodeHandler,
        isLanding,
        isDeactivation,
        isDeactivationAlerts,
        isManageConsents,
      }, this.props);
    }

    getSeoData = ({
      isHome,
      isHubPage,
      isSrpMap,
      isAgencyHome,
      isAgentPro,
      isAccount,
      isSubscription,
      isQrcodeHandler,
      isLanding,
      isDeactivation,
      isDeactivationAlerts,
      isManageConsents,
    }, props) => {
      const {
        home,
        agencyHome,
        hubPage,
        agentPro,
        account,
        subscription,
        qrcodeHandler,
        cercacasa,
        deactivation,
        deactivationAlerts,
        manageConsents,
        location,
        config: {
          siteUrl,
        },
      } = this.props;

      if (isHome) {
        this.seo_data = 'found' in home && !home.found ? null : homeSEOData(home.filters, location.pathname, siteUrl);
      } else if (isAgencyHome) {
        this.seo_data = 'found' in agencyHome && !agencyHome.found ? null : agencyHomeSEOData(agencyHome.filters, location.pathname);
      } else if (isHubPage) {
        this.seo_data = hubSEOData(hubPage, location.pathname);
        this.seo_data.robots_index = true;
      } else if (isAgentPro) {
        this.seo_data = agentPro.seoData;
      } else if (isAccount) {
        this.seo_data = account.seoData;
      } else if (isSubscription) {
        this.seo_data = subscription.seoData;
      } else if (isDeactivation) {
        this.seo_data = deactivation.seoData;
      } else if (isQrcodeHandler) {
        this.seo_data = qrcodeHandler.seoData;
      } else if (isLanding) {
        this.seo_data = cercacasa.seoData;
      } else if (isDeactivationAlerts) {
        this.seo_data = deactivationAlerts.seoData;
      } else if (isManageConsents) {
        this.seo_data = manageConsents.seoData;
      } else {
        this.seo_data = isSrpMap ? props.searchMap.seoDataHeader : props.search.seoDataHeader;
        if (isSrpMap) {
          this.pTypes = props.searchMap.seoDataHeader && props.searchMap.seoDataHeader.pTypes
            ? props.searchMap.seoDataHeader.pTypes
            : {};
        } else {
          this.pTypes = props.search.seoDataHeader && props.search.seoDataHeader.pTypes
            ? props.search.seoDataHeader.pTypes
            : {};
        }
      }
    }

    setUiInfos(ui, user = this.state.user) {
      this.setState({
        device: ui.device,
        viewport: ui.viewport,
        touchscreen: ui.touchscreen,
        isLocalStorageAvailable: ui.isLocalStorageAvailable,
        isShortDesktop: ui.isShortDesktop,
        mobileOs: ui.mobileOs,
        orientation: ui.orientation,
        iosLth12: ui.iosLth12,
        user,
      });
    }

    loginApiCallback = user => this.setUiInfos(userDevice(), user);

    setWinHeight = () => {
      if (
        !document.documentElement.classList.contains('fltrs-locked')
        && !document.documentElement.classList.contains('locked')
      ) {
        setWindowSize();
      }
    }

    computeUiInfos = () => {
      this.setWinHeight();
      const updatedUi = userDevice();
      this.setUiInfos(updatedUi);
    }

    componentDidMount() {
      const { config: { api: { account: accountDomain }, cookiesConfig } } = this.props;
      getSessionUser(this.loginApiCallback, accountDomain, { from: 'componentDidMount', cookiesConfig });
      this.setWinHeight();
      window.addEventListener('resize', this.computeUiInfos, false);
      window.addEventListener('loadOneTapAfterCMPUpdate', this.loadOneTapAfterCMPUpdate);
      this.doc = document.body;
      if (!NodeList.prototype.forEach && Array.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach;
      }
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.computeUiInfos, false);
    }

    componentWillReceiveProps(nextProps) {
      const {
        home,
        agencyHome,
        search,
        searchMap,
        hubPage,
        agentPro,
        account,
        subscription,
        qrcodeHandler,
        cercacasa,
        deactivation,
        deactivationAlerts,
        manageConsents,
      } = this.props;
      const isHome = Object.keys(home).length > 0;
      const isAgencyHome = Object.keys(agencyHome).length > 0;
      const isSrp = Object.keys(search).length > 0;
      const isSrpMap = Object.keys(searchMap).length > 0;
      const isHubPage = Object.keys(hubPage).length > 0;
      const isAgentPro = Object.keys(agentPro).length > 0;
      const isAccount = Object.keys(account).length > 0;
      const isSubscription = Object.keys(subscription).length > 0;
      const isQrcodeHandler = Object.keys(qrcodeHandler).length > 0;
      const isLanding = Object.keys(cercacasa).length > 0;
      const isDeactivation = Object.keys(deactivation).length > 0;
      const isDeactivationAlerts = Object.keys(deactivationAlerts).length > 0;
      const isManageConsents = Object.keys(manageConsents).length > 0;
      this.isHome = isHome;
      if (isSrp || isSrpMap) {
        const isSrpType = isSrpMap ? 'searchMap' : 'search';

        if (
          !isEqual(nextProps[isSrpType].queryFilters, this.props[isSrpType].queryFilters)
          || !isEqual(nextProps[isSrpType].resolvedLocations, this.props[isSrpType].resolvedLocations)
        ) {
          this.getSeoData({
            isHome,
            isHubPage,
            isSrpMap,
            isAgencyHome,
            isAgentPro,
            isAccount,
            isSubscription,
            isQrcodeHandler,
            isLanding,
            isDeactivation,
            isDeactivationAlerts,
            isManageConsents,
          }, nextProps);
        }
      }
    }

    loadOneTapAfterCMPUpdate = () => {
      this.setState({ waitingCMP: false });
      window.removeEventListener('loadOneTapAfterCMPUpdate', this.loadOneTapAfterCMPUpdate);
    };

    bridgeRefreshUser = async ({
      user,
      userData,
      loginMedium,
      loginDate,
      from,
      fromSocial,
      forceShowModalConsent,
    }) => {
      const composedFD = fillFormData(user);
      const newState = {
        user: {
          displayName: user.displayname,
          id: user.id,
          isAuthenticated: true,
          checked: true,
          hasToShowModalConsent: forceShowModalConsent || (user.hasToShowModalConsent && !fromSocial),
          isPublisherBlacklisted: user.isPublisherBlacklisted,
          sess: userData.user,
          username: user.email || user.username,
          formData: composedFD,
        },
      };

      const previousSigninData = getItem('signinData');

      const otInfos = manageSigninData({
        login: true,
        track: true,
        from,
        medium: loginMedium,
        email: user.email || user.username,
        formData: composedFD,
        timeStamp: loginDate,
        newUser: userData.newUser,
      });

      /* aggiorna l'utente per tealium */
      const tdl = window.tealiumDl || {};
      tdl.user = {
        hash: user.cEmail,
        status: 1,
        type: 1,
      };
      window.tealiumDl = tdl;
      /* aggiorna l'utente per tealium */
      try {
        // priorità alla modale dei consensi obbligatori rispetto a quella di benvenuto
        if (otInfos && otInfos.isOneTap && !forceShowModalConsent) {
          if (userData.newUser) {
            newState.showConsentModal = 'welcome';
          } else {
            const showConsent = getShowConsentRequest({ signinData: previousSigninData, jwt: userData.user });
            if (showConsent) {
              newState.showConsentModal = 'welcomeback';
            }
          }
        }
      } catch (error) {
        console.log('otInfos (manageSigninData) error: ', error);
      }

      this.setState(newState);

      // reset also the bookmarks inside LS data, no bookmark data has to be left 
      // in LS after a login, otherwise different users bookmarks could be mixed
      removeItem(BOOKMARKED_ADS_LS);
      removeItem(BOOKMARKED_SEARCHES_LS);
      removeItem(SYNCH_WITH_MYCASA);
    };

    refreshUser = async (userData, isFromOneTap = false, { from, hasToShowModalConsent }) => {
      const userJWT = parseJwt(userData.user);
      const { user, iat: loginDate } = userJWT;
      this.bridgeRefreshUser({
        user,
        userData,
        loginMedium: isFromOneTap ? 6 : 3,
        loginDate,
        from,
        forceShowModalConsent: hasToShowModalConsent,
      });
    };

    refreshSocialUser = (user, loginData, { from }) => {
      if (user.sess) {

        const { user: { accessToken } } = parseJwt(user.sess);

        this.bridgeRefreshUser({
          user: { ...user, accessToken },
          userData: { user: user.sess },
          ...loginData,
          fromSocial: true,
          from,
        });
        user.handleSocialLoginClb(user);
      }
    };

    userLogIn = (handleLoginRes, data) => {
      const { config: { api: { account: accountDomain }, cookiesConfig = {} } } = this.props;
      const {
        isGoogleOneTap = false,
        isSocialLogin = false,
        from,
      } = data;
      if (isGoogleOneTap) {
        googleOneTapLogin(data, this.refreshUser, { cookiesConfig, from, accountDomain });
      } else if (isSocialLogin) {
        getSessionUser(this.refreshSocialUser, accountDomain, { handleLoginRes, cookiesConfig, from });
      } else {
        logIn(handleLoginRes, data, accountDomain, this.refreshUser, { cookiesConfig, from });
      }
    }

    userRegistrationSilently = (handleRegistrationRes, data) => {
      const { config: { api: { account: accountDomain }, cookiesConfig = {} } } = this.props;
      registerInSilently(handleRegistrationRes, data, accountDomain, { cookiesConfig });
    }

    userRegistration = (handleRegistrationRes, data) => {
      const { config: { api: { account: accountDomain }, cookiesConfig = {} } } = this.props;
      registerIn(handleRegistrationRes, data, accountDomain, { cookiesConfig });
    }

    userLogOut = async () => {
      const { config: { api: { account: accountDomain, authBasePrefix } } } = this.props;
      manageSigninData({ logout: true });
      /* aggiorna l'utente per tealium */
      const tdl = window.tealiumDl || {};
      tdl.user = {
        status: 2,
      };
      window.tealiumDl = tdl;
      /* aggiorna l'utente per tealium */
      const isLoggedOut = await logOut(`${accountDomain}${authBasePrefix}`);
      if (isLoggedOut?.status === 200) {
        this.setState({
          user: {
            isAuthenticated: false,
          },
        });
      }
    }

    updateUser = newUser => this.setState({ user: newUser });

    updateUserFormData = (payload) => {
      const { user } = this.state;
      manageSigninData({
        updateFormData: true,
        formData: {
          ...payload,
          name: payload.firstName,
        },
      });
      this.setState({
        user: {
          ...user,
          formData: payload,
        },
      });
    };

    getModalAction = (target) => {
      if ('getAttribute' in target) {
        return target.getAttribute('data-action');
      }
      return target.action;
    }

    appReducer = async (type, payload, clb) => {
      const newState = await reducerApp(this.state, type, payload);
      this.setState(newState, clb);
      return newState;
    }

    render() {
      return (
        <Content
          {...this.props}
          {...this.state}
          seoData={this.seo_data}
          pTypes={this.pTypes}
          userLogIn={this.userLogIn}
          userRegistration={this.userRegistration}
          userRegistrationSilently={this.userRegistrationSilently}
          userLogOut={this.userLogOut}
          updateUser={this.updateUser}
          updateUserFormData={this.updateUserFormData}
          appReducer={this.appReducer}
        />
      );
    }
  }

  const UiHandler = fetch(fetchFunction)(UiHandlerComponent);
  return UiHandler;
}
