import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';
import RadioField from 'shared-components/components/RadioField';

import PoiBus from 'shared-vectors/icons/PoiBus';
import PoiMetro from 'shared-vectors/icons/PoiMetro';
import PoiRecharge from 'shared-vectors/icons/PoiRecharge';
import PoiSchoolElementary from 'shared-vectors/icons/PoiSchoolElementary';
import PoiSchoolHigh from 'shared-vectors/icons/PoiSchoolHigh';
import PoiSchoolMiddle from 'shared-vectors/icons/PoiSchoolMiddle';
import PoiSchoolNursery from 'shared-vectors/icons/PoiSchoolNursery';
import PoiSupermarket from 'shared-vectors/icons/PoiSupermarket';
// import PoiHospital from 'shared-vectors/icons/PoiHospital';
// import PoiPharmacy from 'shared-vectors/icons/PoiPharmacy';
import { Icon } from 'shared-vectors';

import { useCtxGlobal } from '@client/app/Container';

import { fromArrayToObject } from '@helpers/dataModels/poi';

import './style.scss';

const iconsMap = {
  bus_station: PoiBus,
  subway: PoiMetro,
  electric_charging: PoiRecharge,
  elementary_school: PoiSchoolElementary,
  high_school: PoiSchoolHigh,
  secondary_school: PoiSchoolMiddle,
  nursery_school: PoiSchoolNursery,
  supermarket: PoiSupermarket,
  // case: PoiHospital,
  // case: PoiPharmacy,
};

const Pois = ({
  selectedPoi,
  updateFilters,
  queryBuilderProps,
  inModal,
}) => {
  const correctPoi = queryBuilderProps
    ? queryBuilderProps.filters.poi
    : selectedPoi;

  const [currentPoi, setCurrentPoi] = useState(correctPoi && Object.keys(correctPoi)[0]);

  const { globalSelector } = useCtxGlobal();
  const list = globalSelector('availablePoi');

  const setValue = (val) => {
    setCurrentPoi(val);
    const newFiltersVal = !val
      ? null
      : fromArrayToObject([val]);
    inModal
      ? queryBuilderProps.setQueryBuilderTempFiltersFromPanel({ ...queryBuilderProps.filters, poi: newFiltersVal })
      : updateFilters('poi', newFiltersVal);
  };

  const resetToPrevious = queryBuilderProps
    ? queryBuilderProps.resetToPrevious
    : null; 

  useEffect(() => {
    const val = correctPoi
      ? Object.keys(correctPoi)[0]
      : null;
    setCurrentPoi(val);
  }, [resetToPrevious]);

  useEffect(() => {
    if (correctPoi !== currentPoi) {
      const val = correctPoi
        ? Object.keys(correctPoi)[0]
        : null;
      setCurrentPoi(val);
    }
  }, [correctPoi]);

  const baseCls = `poiPanel${inModal ? '--mdl' : ''}`;
  const resetCls = `${baseCls}__item${inModal ? ' c-bg--w pr--m pl--m' : ''}`;
  const itemCls = `${resetCls}${inModal ? ' bb--s' : ''}`;
  const bigTxtCls = isSel => `tp-s--m ${isSel ? 'tp-w--l c-txt--secondary' : 'tp-w--m c-txt--f0'}`;
  return (
    <Grid
      className={`${baseCls}${inModal ? ' pt--m' : ''}`}
      direction="column"
    >
      <Grid
        className={resetCls}
        align="center"
      >
        <RadioField
          className="poiPanel__radio"
          value={null}
          onChange={setValue}
          checked={currentPoi === null}
          align="center"
        >
          <Grid align="center">
            <GridItem behavior="fixed">
              <div className="poiPanel__icon poiPanel__icon--reset is-rel b-r--pill" />
            </GridItem>
            <GridItem>
              <p className={bigTxtCls(currentPoi === null)}>Nessuna scelta</p>
            </GridItem>
          </Grid>
        </RadioField>
      </Grid>
      {
        inModal
          && (
            <p className={`${baseCls}__msg pr--m pl--m tp-s--xs tp-w--m tp--up c-txt--f0`}>
              Seleziona un punto di interesse
            </p>
          )
      }
      {list.map(poi => (
        <Grid
          key={poi.id}
          className={itemCls}
          align="center"
        >
          <RadioField
            className="poiPanel__radio"
            value={poi.id}
            onChange={setValue}
            checked={currentPoi === poi.id}
            align="center"
          >
            <Grid align="center">
              <GridItem behavior="fixed" style={{ color: 'transparent' }}>
                <Icon name={iconsMap[poi.id]} width="24px" height="24px" className="poiPanel__icon" />
              </GridItem>
              <GridItem>
                <p className={bigTxtCls(currentPoi === poi.id)}>{poi.pluralName}</p>
                <p className="poiPanel__dist tp-w--m c-txt--f5">entro {poi.maxDistance} metri</p>
              </GridItem>
            </Grid>
          </RadioField>
        </Grid>
      ))}
    </Grid>
  );
};

export default Pois;

Pois.propTypes = {
  selectedPoi: PropTypes.instanceOf(Object),
  updateFilters: PropTypes.func,
  queryBuilderProps: PropTypes.instanceOf(Object),
  inModal: PropTypes.bool,
};

Pois.defaultProps = {
  selectedPoi: null,
  updateFilters: () => {},
  queryBuilderProps: null,
  inModal: false,
};
