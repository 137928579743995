// @flow
import * as actions from './actions';

const initialState = {}; // new InitialState;

export default function searchReducer(state: Object = initialState, action: Object) {
  switch (action.type) {
    case actions.FETCH_SEARCH_START: {
      return Object.assign({}, state, {
        isFetching: true,
        isFetchingScroll: false,
        scrollPage: 0,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCH_SUCCESS: {
      return Object.assign({}, action.payload, state, {
        isFetching: false,
        isFetchingScroll: false,
        scrollPage: parseInt(action.payload.queryFilters.page, 10),
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCH_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingScroll: false,
        scrollPage: parseInt(action.payload.queryFilters.page, 10),
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_SEARCH_SCROLL_START: {
      return Object.assign({}, state, {
        isFetchingScroll: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCH_SCROLL_SUCCESS: {
      const decorateFirstAd = (list, page) => {
        if (list[0]) {
          list[0].isScrollPageFirst = parseInt(page, 10);
        }
        return list;
      };
      return Object.assign({}, state, { list: state.list.concat(decorateFirstAd(action.payload.list, action.payload.queryFilters.page)) }, {
        isFetchingScroll: false,
        scrollPage: parseInt(action.payload.queryFilters.page, 10),
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCH_SCROLL_ERROR: {
      return Object.assign({}, state, {
        isFetchingScroll: false,
        scrollPage: parseInt(action.payload.queryFilters.page, 10),
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_SEARCH_FILTERS_START: {
      return Object.assign({}, state, {
        isFetching: true,
        isFetchingScroll: false,
        scrollPage: 0,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCH_FILTERS_SUCCESS: {
      return Object.assign({}, state, action.payload, {
        isFetching: false,
        isFetchingScroll: false,
        scrollPage: parseInt(action.payload.queryFilters.page, 10),
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCH_FILTERS_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingScroll: false,
        scrollPage: parseInt(action.payload.queryFilters.page, 10),
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_SEARCH_AVAILABLE_RESULTS_START: {
      return Object.assign({}, state, {
        isFetchingAvailableResult: true,
        availableResults: 0,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCH_AVAILABLE_RESULTS_SUCCESS: {
      return Object.assign({}, state, {
        isFetchingAvailableResult: false,
        availableResults: action.payload.total,
        availablePoi: action.payload.availablePoi,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCH_AVAILABLE_RESULTS_ERROR: {
      return Object.assign({}, state, {
        isFetchingAvailableResult: false,
        availableResults: 0,
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_SEARCH_GEO_START: {
      return Object.assign({}, state, {
        isFetching: true,
        isFetchingScroll: false,
        scrollPage: 0,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCH_GEO_SUCCESS: {
      const validItems = action.payload.list.filter(item => parseFloat(item.property.geo.lat) && parseFloat(item.property.geo.lon));
      // PIO TODO console.log("SULLA FETCH_SEARCH_GEO_SUCCESS LEN")
      return Object.assign({}, state, action.payload, {
        validItems,
        sid: Math.random(),
        isFetching: false,
        isFetchingScroll: false,
        scrollPage: parseInt(action.payload.queryFilters.page, 10),
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCH_GEO_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingScroll: false,
        scrollPage: parseInt(action.payload.queryFilters.page, 10),
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_SEARCH_GEO_BOUNDS_START: {
      return Object.assign({}, state, {
        isFetching: true,
        isFetchingScroll: false,
        scrollPage: 0,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCH_GEO_BOUNDS_SUCCESS: {
      // # console.log('REDUCER --- FETCH_SEARCH_GEO_BOUNDS_SUCCESS LIST 1');
      // # console.log('REDUCER payload', action.payload);
      // action.payload.list = _unionby(state.list, action.payload.list, 'id');

      // console.log(state);

      const validItems = action.payload.list.filter(item => parseFloat(item.property.geo.lat) && parseFloat(item.property.geo.lon));
      return Object.assign({}, state, action.payload, {
        validItems,
        sid: Math.random(),
        isFetching: false,
        isFetchingScroll: false,
        scrollPage: parseInt(action.payload.queryFilters.page, 10),
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCH_GEO_BOUNDS_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingScroll: false,
        scrollPage: parseInt(action.payload.queryFilters.page, 10),
        errorMessage: action.payload.message,
      });
    }
    case actions.FETCH_AGENCY_SEARCH_START: {
      return Object.assign({}, state, {
        isFetching: true,
        isFetchingScroll: false,
        scrollPage: 0,
        errorMessage: '',
      });
    }

    case actions.FETCH_AGENCY_SEARCH_SUCCESS: {
      return Object.assign({}, action.payload, state, {
        isFetching: false,
        isFetchingScroll: false,
        scrollPage: parseInt(action.payload.queryFilters.page, 10),
        errorMessage: '',
      });
    }

    case actions.FETCH_AGENCY_SEARCH_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingScroll: false,
        scrollPage: parseInt(action.payload.queryFilters.page, 10),
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_AGENCY_SEARCH_SCROLL_START: {
      return Object.assign({}, state, {
        isFetchingScroll: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_AGENCY_SEARCH_SCROLL_SUCCESS: {
      return Object.assign({}, state, { list: state.list.concat(action.payload.list) }, {
        isFetchingScroll: false,
        scrollPage: parseInt(action.payload.queryFilters.page, 10),
        errorMessage: '',
      });
    }

    case actions.FETCH_AGENCY_SEARCH_SCROLL_ERROR: {
      return Object.assign({}, state, {
        isFetchingScroll: false,
        scrollPage: parseInt(action.payload.queryFilters.page, 10),
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_AGENCY_SEARCH_FILTERS_START: {
      return Object.assign({}, state, {
        isFetching: true,
        isFetchingScroll: false,
        scrollPage: 0,
        errorMessage: '',
      });
    }

    case actions.FETCH_AGENCY_SEARCH_FILTERS_SUCCESS: {
      return Object.assign({}, state, action.payload, {
        isFetching: false,
        isFetchingScroll: false,
        scrollPage: parseInt(action.payload.queryFilters.page, 10),
        errorMessage: '',
      });
    }

    case actions.FETCH_AGENCY_SEARCH_FILTERS_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingScroll: false,
        scrollPage: parseInt(action.payload.queryFilters.page, 10),
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_AGENCY_SEARCH_AVAILABLE_RESULTS_START: {
      return Object.assign({}, state, {
        isFetchingAvailableResult: true,
        availableResults: 0,
        errorMessage: '',
      });
    }

    case actions.FETCH_AGENCY_SEARCH_AVAILABLE_RESULTS_SUCCESS: {
      return Object.assign({}, state, {
        isFetchingAvailableResult: false,
        availableResults: action.payload.total,
        availablePoi: action.payload.availablePoi,
        errorMessage: '',
      });
    }

    case actions.FETCH_AGENCY_SEARCH_AVAILABLE_RESULTS_ERROR: {
      return Object.assign({}, state, {
        isFetchingAvailableResult: false,
        availableResults: 0,
        errorMessage: action.payload.message,
      });
    }

    case actions.UPDATE_SEARCH_PAGINATOR_START: {
      return Object.assign({}, state, {
        errorMessage: '',
      });
    }

    case actions.UPDATE_SEARCH_PAGINATOR_SUCCESS: {
      if (action?.payload?.paginator) {
        return {
          ...state,
          paginator: action.payload.paginator,
        };
      }
      return state;
    }

    case actions.UPDATE_SEARCH_PAGINATOR_ERROR: {
      return Object.assign({}, state, {
        errorMessage: action.payload.message,
      });
    }

    default: {
      return state;
    }

  }
}
