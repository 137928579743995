// @flow
import { combineReducers } from 'redux';
import config from './redux/config/reducer';
import home from './redux/home/reducer';
import agencyHome from './redux/agencyHome/reducer';
import intl from './redux/intl/reducer';
import search from './redux/search/reducer';
import searchMap from './redux/searchMap/reducer';
import hubPage from './redux/hubPage/reducer';
import agentPro from './redux/agentPro/reducer';
import account from './redux/account/reducer';
import subscription from './redux/subscription/reducer';
import qrcodeHandler from './redux/qrcodeHandler/reducer';
import cercacasa from './redux/cerca-casa/reducer';
import deactivationAlerts from './redux/deactivationAlerts/reducer';
import manageConsents from './redux/manageConsents/reducer';


const configureReducer = (platformReducers: Object, initialState: Object) => {
  const reducer = combineReducers({
    ...platformReducers,
    config,
    home,
    agencyHome,
    agencySrp: search,
    intl,
    search,
    searchMap,
    hubPage,
    agentPro,
    account,
    subscription,
    deactivation: subscription,
    qrcodeHandler,
    cercacasa,
    deactivationAlerts,
    manageConsents,
  });

  return reducer;
};

export default configureReducer;
